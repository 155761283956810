export enum QueriesKeysEnum {
  mobileApps = 'mobileApps',
  hasLoyalty = 'hasLoyalty',
  storeItems = 'storeItems',
  topSellingItems = 'topSellingItems',
  lastOrders = 'lastOrders',
  orders = 'orders',
  packingInstructions = 'packingInstructions',
  recommendedItems = 'recommendedItems',
  applicablePromotions = 'applicablePromotions',
  applicableLoyalties = 'applicableLoyalties',
  BULK_CATEGORIES = 'BULK_CATEGORIES',
  BULK_STORE_ITEMS = 'BULK_STORE_ITEMS',
  GET_COMBO = 'GET_COMBO',
  categoryItems = 'categoryItems',
  storeItemsByUniqueNames = 'storeItemsByUniqueNames',
  getUserSubscriptions = 'getUserSubscriptions',
  loyaltyIframeUrl = 'LoyaltyIframeUrl',
  userGiftCards = 'userGiftCards',
}
