import { useTheme } from '@shopify/restyle';
import { usePatchVendorOrder } from '@webstore-monorepo/shared/api/vendor-orders-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CheckIcon, MealIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef, useState } from 'react';

export type ConfirmArrivalProps = {
  onClose: () => void;
  onConfirm: () => void;
  orderId: string;
  firstName?: string;
};
export const ConfirmArrival = forwardRef(({ onConfirm, orderId, onClose, firstName }: ConfirmArrivalProps, ref) => {
  const { isMobile } = useWindowDimensions();
  const { orderReminderBanner } = useComponentsConfig();
  const { notification } = usePlatformStoreState();
  const [isSuccessState, setSuccessState] = useState(false);
  const { mutate: patchVendorOrder, isLoading } = usePatchVendorOrder({
    orderUuid: orderId,
    options: {
      onSuccess: () => {
        setSuccessState(true);
        onConfirm();
      },
      onError: (error) => {
        if (error?.response?.data?.message) {
          notification.error(error.response.data.message);
        }
      },
    },
  });
  const theme = useTheme<Theme>();

  const primaryBG = theme.colors['primaryColor'];

  // TODO: create a utility function in libs and reuse here. Also move @libs/ui/scrollable-box/utils/colors to the same lib
  const opacityBackground = !primaryBG.includes('#')
    ? primaryBG
        .split(',')
        .map((item, index) => {
          if (index === 3) {
            return ' 0.1)';
          }
          return item;
        })
        .join(',')
    : primaryBG.length === 4
    ? `#${primaryBG[1]}${primaryBG[1]}${primaryBG[2]}${primaryBG[2]}${primaryBG[3]}${primaryBG[3]}19`
    : `${primaryBG}19`;

  const handleConfirmArrival = () => {
    patchVendorOrder();
  };

  return (
    <Box ref={ref} {...orderReminderBanner?.wrapper?.confirmModal?.style} {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.style : {})}>
      <Box justifyContent="center" alignItems="center">
        <Box
          width={80}
          minWidth={80}
          height={80}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
          borderRadius="rounded"
          testID="confirm-arrival-textbox"
          style={{ backgroundColor: opacityBackground }}
        >
          {isSuccessState ? <CheckIcon fill="primaryColor" stroke="primaryColor" /> : <MealIcon fill="primaryColor" stroke="primaryColor" />}
        </Box>
        <Text
          textAlign="center"
          fontSize="xxl"
          lineHeight={32}
          fontWeight="700"
          marginBottom={4}
          testID="confirm-arrival-primary-text"
          {...orderReminderBanner?.wrapper?.confirmModal?.primary?.style}
          {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.primary?.style : {})}
        >
          {isSuccessState
            ? `Thank you, ${firstName}`
            : isMobile
            ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.primary
            : orderReminderBanner?.wrapper?.confirmModal?.options?.primary}
        </Text>
        <Text
          textAlign="center"
          fontSize="m"
          lineHeight={24}
          marginBottom={8}
          testID="confirm-arrival-secondary-text"
          {...orderReminderBanner?.wrapper?.confirmModal?.secondary?.style}
          {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.secondary?.style : {})}
        >
          {isSuccessState
            ? isMobile
              ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.success
              : orderReminderBanner?.wrapper?.confirmModal?.options?.success
            : isMobile
            ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.secondary
            : orderReminderBanner?.wrapper?.confirmModal?.options?.secondary}
        </Text>
      </Box>
      <Box flexDirection="column" alignItems="stretch" style={{ gap: 16 }}>
        {!isSuccessState && (
          <Box flexGrow={1}>
            <Button
              isLoading={isLoading}
              size="full-width"
              buttonStyle={{ height: 40, fontWeight: '600' }}
              onPress={handleConfirmArrival}
              testID="confirm-arrival-im-here-btn"
              fontSize="m"
            >
              I'm here
            </Button>
          </Box>
        )}
        <Box flexGrow={1}>
          <Button
            size="full-width"
            buttonStyle={{ height: 40, fontWeight: '600' }}
            onPress={onClose}
            variant="stroked"
            testID="confirm-arrival-cancel-btn"
            fontSize="m"
          >
            {isSuccessState ? 'Close' : 'Cancel'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
