import { useMediaQuery } from '@chakra-ui/media-query';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import Drawer from 'rc-drawer';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect } from 'react';

import { WrappedInlineLoader } from '../../../components/uielements/loaders';
import { useModalUtils } from '../../../components/uielements/modal/useModalUtils';
import { useAppContextState } from '../../contexts/app-context-provider';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  wrapperRef?: any;
  isFullHeight?: boolean;
  /**
   * For legacy modal window classes. Will be deprecated
   */
  classNames?: string;
  styles?: Record<string, string>;
  drawerContentStyles?: Record<string, string>;
}

export const DrawerWrapper: React.FC<PropsWithChildren<Props>> = ({ isFullHeight, isLoading, children, classNames = '', drawerContentStyles }) => {
  const [isLessThanMd] = useMediaQuery('(max-width: 48em)');
  const appContext = useAppContextState();
  const { onModalClose } = useModalUtils();
  const { header, sharedComponents } = useComponentsConfig();
  const headerHeight = appContext.headerElementHeight ?? header?.wrapper?.style?.height;
  const [top, setTop] = React.useState<number | string>(0);
  const mainHeaderHeight = isFullHeight ? '0px' : `${top}px`;

  useEffect(() => {
    if (headerHeight) {
      setTop((prev) => {
        if (headerHeight > prev) {
          return headerHeight;
        }
        return prev;
      });
    }
  }, [headerHeight, top]);

  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    return () => {
      window.document.body.style.overflow = '';
    };
  }, []);

  const handleCloseModal = useCallback(() => onModalClose(), [onModalClose]);

  return (
    <Drawer
      keyboard
      open={true}
      onClose={handleCloseModal}
      className={`modal full-height ${classNames}`}
      placement="right"
      rootStyle={{
        top: isLessThanMd ? 0 : mainHeaderHeight,
        color: sharedComponents?.modal?.content?.style?.color,
      }}
      contentWrapperStyle={{
        maxWidth: isLessThanMd ? '100%' : '700px',
        backgroundColor: '#f9f9f9',
        width: isLessThanMd ? '100%' : '60%',
        maxHeight: isLessThanMd ? '100%' : `calc(var(--vh, 1vh) * 100 - ${mainHeaderHeight})`,
        ...drawerContentStyles,
      }}
    >
      {isLoading ? <WrappedInlineLoader /> : children}
    </Drawer>
  );
};
