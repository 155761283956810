import styled from 'styled-components';

import { media } from '../../../shared/utils/media-queries';

export const SelectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fefefe;
`;

export const SelectionGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet} {
    border-bottom: 1px solid #f7f7f7;
    flex-direction: row;
  }
`;

// TODO: move to a abstract component with generic styles.
export const CheckboxContainer = styled.div<{ isItemBaseGroup?: boolean; invalid?: boolean; isGroupShown?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  ${media.tablet} {
    ${({ isItemBaseGroup, isGroupShown }) =>
      isItemBaseGroup
        ? `
      display: flex;
      width: 50%;
      margin-top: 10px;
    `
        : `
      display: grid;
      grid-template-rows: auto ${isGroupShown ? '70px' : 'auto'};
      width: 50%;
    `}

    &:nth-child(2n) {
      padding-right: 15px;
      padding-left: 20px;
    }
  }

  ${(props) =>
    props.invalid &&
    `
    cursor: no-drop;

    label:before {
      display: none;
    }
  `}
`;
// TODO: move to a abstract component with generic styles.
export const CheckBoxLabelContainer = styled.div`
  display: flex;
`;

export const LabelThumbnail = styled.label`
  display: inline-block;
  margin-right: 0;
  img {
    max-width: 100px;
  }
  ${media.desktop} {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
export const SelectionLabel = styled.label<{ unavailable?: boolean; disabled?: boolean }>`
  ${media.desktop} {
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: var(--add-ons-selection-color);
    ${(props) =>
      props.unavailable &&
      `
        color: #b6b3b3;
        line-height: 20px;
        cursor: default;
    `}
    ${(props) => props.disabled && `cursor: default;`}
  }
`;

export const SelectionPrice = styled.span`
  display: block;
  font-weight: 700;
`;

export const SelectionTitle = styled.span`
  font-size: 12px;

  ${media.desctop} {
    font-size: 14px;
  }
`;

export const SelectionLabelSubtitle = styled.span`
  display: block;
  font-size: 11px;
`;

export const SelectGroupSignal = styled.div`
  height: 20px;
  background: white;
  display: flex;
  margin-top: 3px;
`;

export const SelectionGroup = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  position: relative;
`;

export const SelectionGroupProperties = styled.div<{ isClickable?: boolean }>`
  display: flex;
  position: relative;
  ${(prop) => prop.isClickable && 'cursor: pointer;'}
`;

export const SelectionGroupItem = styled.label<{ order?: number; select?: boolean; unselected?: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  cursor: pointer;

  &:last-of-type {
    margin: 0;
  }
  ${(prop) =>
    prop.select &&
    `
    width: 30px;
    height: 30px;
  `}
  ${(prop) =>
    prop.unselected &&
    `
    order: 100;
    margin-right: 5px;
  `}
  order: ${(prop) => prop.order}
`;

export const SelectGroup = styled.div<{ isVisible?: boolean }>`
  display: flex;
  height: 0;
  padding-top: 10px;
  transition: opacity 0.3s;
  visibility: hidden;
  width: 100%;
  ${(props) =>
    props.isVisible &&
    `
    visibility: visible;
    height: auto;
  `}

  & input {
    display: none !important;
  }
`;

// TODO: move to a abstract component with generic styles.
export const PropertyTitle = styled.div`
  margin-left: 5px;
  color: var(--add-ons-selection-color);
`;
